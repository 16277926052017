<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">
              <a-form-item label="图标" prop="icon">
                <a-input v-model="queryParam.icon" placeholder="请输入图标" allow-clear />
              </a-form-item>
            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('服务管理.服务内容管理.名称')" prop="name">
                <a-input v-model="queryParam.name" :placeholder="$t('服务管理.服务内容管理.请输入名称')" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--              <a-col :md="8" :sm="24">
                <a-form-item label="描述" prop="describe">
                  <a-input v-model="queryParam.describe" placeholder="请输入描述" allow-clear />
                </a-form-item>
              </a-col>-->
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['serve:content:add']">
          <a-icon type="plus" />{{ $t('通用.新增') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['serve:content:edit']"
        >
          <a-icon type="edit" />{{ $t('通用.修改') }}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['serve:content:remove']">
          <a-icon type="delete" />{{ $t('通用.删除') }}
        </a-button>
        <!-- <a-button type="primary" @click="handleExport" v-hasPermi="['serve:content:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="icon" slot-scope="text, record" v-if="record.icon">
          <img v-viewer style="width: 48px; height: 48px; margin-top: 5px; margin-right: 5px" :src="record.icon" />
        </span>
        <template slot="status" slot-scope="text, record">
          <span style="color: #409eff">{{ record.status == 0 ? '禁用' : '启用' }}</span>
        </template>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['serve:content:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['serve:content:edit']">
            <a-icon type="edit" />{{ $t('通用.修改') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['serve:content:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['serve:content:remove']"> <a-icon type="delete" />{{$t('通用.删除')}} </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageContent, listContent, delContent } from '@/api/serve/content'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Content',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        icon: null,
        name: null,
        describe: null,
        status: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: this.$t('服务管理.服务内容管理.id'),
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('服务管理.服务内容管理.图标'),
          dataIndex: 'icon',
          scopedSlots: { customRender: 'icon' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('服务管理.服务内容管理.名称'),
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('服务管理.服务内容管理.描述'),
          dataIndex: 'describe',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('服务管理.服务内容管理.是否启用'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('服务管理.服务内容管理.添加时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('服务管理.服务内容管理.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询服务内容列表 */
    getList() {
      this.loading = true
      pageContent(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        icon: undefined,
        name: undefined,
        describe: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('服务管理.服务内容管理.确认删除所选中数据'),
        content:  this.$t('服务管理.服务内容管理.当前选中编号为') + ids ,
        okText: this.$t('通用.确认') ,
        cancelText: this.$t('通用.取消') ,
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delContent(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'serve/serve-content/export',
            {
              ...that.queryParam,
            },
            `服务内容_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'ID',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'icon',
            dataIndex: 'icon',
            scopedSlots: { customRender: 'icon' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Description',
            dataIndex: 'describe',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Enable or not',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Add time',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
        'zh-CN': [
             {
            title: 'id',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '图标',
            dataIndex: 'icon',
            scopedSlots: { customRender: 'icon' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '名称',
            dataIndex: 'name',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '描述',
            dataIndex: 'describe',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '是否启用',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '添加时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },  
        ],
        
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
<style scoped lang="less">
.image {
  width: 60px !important;
  height: 60px !important;
}
</style>
